@charset "utf-8";
@import "_setting";
@import "_mixin";

body#top{
	.u-top_feature{
		.box{
			@include flex(wrap,left,space-between);
			-ms-flex-align: stretch;
			align-items: stretch;
			.feature-img{
				width: 40%;
				@include flex(wrap,center,center);
				-ms-flex-align: center;
				align-items: center;
				img{
					width: 100%;
					height: auto;
				}
			}
	        .txt{
				width: 60%;
				min-height: 300px;
				position: static;
				left: auto;
				top: auto; 
				padding: 60px 30px;
				-webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
				box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
				-webkit-transform: translate(0, 0);
				-ms-transform: translate(0, 0);
				transform: translate(0, 0);
				margin: 0;
	        }
		}
		.box:nth-child(odd) {
			flex-direction: row-reverse;
		}
	}
}

@include media(sp){
	body#top{
		.u-top_feature{
			.box{
				display: block;
				.feature-img{
					width: auto;
					display: block;
					img{
						width: 100%;
						height: auto;
					}
				}
		        .txt{
					width: auto;
					min-height: auto;
					padding: 15px;
		        }
			}
			.box:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}
	}

}
html{
}
body.common{
}
@media screen and (min-width: 980px) {
	.pc{
	}
	.sp{
	}
}
@media screen and (max-width: 980px) {
	.pc{
	}
	.sp{
	}
body.common{

}

}
